import { createApi } from '@reduxjs/toolkit/query/react'
import { AxiosRequestHeaders } from 'axios'
import { axiosBaseQuery } from 'shared/axios-settings'
import { IGetTasksResponse, ITask } from 'types'

const taskAPISlice = createApi({
  reducerPath: 'taskApi',
  tagTypes: ['Tasks'],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => {
    return {
      getTasks: builder.query<IGetTasksResponse, { eventCode: string }>({
        query: ({ eventCode }) => ({
          url: `${process.env.BASE_API_URL}/delta/tasks/?event_code=${eventCode}`,
          headers: {
            'Gp-App-Id': process.env.APP_ID,
          } as AxiosRequestHeaders,
        }),
        providesTags: ['Tasks'],
      }),

      performTask: builder.mutation<unknown, { eventCode: string; taskId: ITask['id'] }>({
        query: ({ eventCode, taskId }) => ({
          url: `${process.env.BASE_API_URL}/delta/tasks/`,
          method: 'POST',
          headers: {
            'Gp-App-Id': process.env.APP_ID,
          } as AxiosRequestHeaders,
          data: {
            event_code: eventCode,
            task_id: taskId,
          },
        }),
      }),
    }
  },
})

export const { useGetTasksQuery } = taskAPISlice

export default taskAPISlice
